import React, { Component } from 'react'
import moment from 'moment-timezone'
import { getVolumes, groupList } from '../../api/netting'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import MomentLocaleUtils from 'react-day-picker/moment';
import LocalizedStrings from '../../components/Localization'
import Loader from '../../components/Loader';
import { CSVLink } from 'react-csv';
import { ResponsiveContainer, BarChart, Line, Area, LineChart, Legend, ReferenceLine, Bar, XAxis, YAxis, CartesianGrid, Tooltip, AreaChart } from 'recharts';


moment.tz.setDefault('Europe/Brussels')


let periods = {
    'tomorrow': {
        from: moment().add(1, 'days').startOf('day').toDate(),
        to: moment().add(1, 'days').endOf('day').toDate(),
        agg: 'h'
    },
    'today': {
        from: moment().startOf('day').toDate(),
        to: moment().endOf('day').toDate(),
        agg: 'h'
    },
    'yesterday': {
        from: moment().subtract(1, 'days').startOf('day').toDate(),
        to: moment().subtract(1, 'days').endOf('day').toDate(),
        agg: 'h'
    },
    'thisWeek': {
        from: moment().startOf('week').toDate(),
        to: moment().endOf('week').toDate(),
        agg: 'd'
    },
    'previousWeek': {
        from: moment().subtract(1, 'weeks').startOf('week').toDate(),
        to: moment().subtract(1, 'weeks').endOf('week').toDate(),
        agg: 'd'
    },
    'thisMonth': {
        from: moment().startOf('month').toDate(),
        to: moment().endOf('month').toDate(),
        agg: 'd'
    },
    'previousMonth': {
        from: moment().subtract(1, 'month').startOf('month').toDate(),
        to: moment().subtract(1, 'month').endOf('month').toDate(),
        agg: 'd'
    },
    'thisQuarter': {
        from: moment().quarter(moment().quarter()).startOf('quarter').toDate(),
        to: moment().quarter(moment().quarter()).endOf('quarter').toDate(),
        agg: 'd'
    },
    'previousQuarter': {
        from: moment().quarter(moment().quarter()).subtract(1, 'quarters').startOf('quarter').toDate(),
        to: moment().quarter(moment().quarter()).subtract(1, 'quarters').endOf('quarter').toDate(),
        agg: 'd'
    },
    'thisYear': {
        from: moment().startOf('year').toDate(),
        to: moment().endOf('year').toDate(),
        agg: 'm'
    },
    'previousYear': {
        from: moment().subtract(1, 'years').startOf('year').toDate(),
        to: moment().subtract(1, 'years').endOf('year').toDate(),
        agg: 'm'
    },
    'custom': {
        from: undefined,
        to: undefined
    }
}
class NettingChart extends Component {

    constructor(props) {

        super(props)

        this.csvLink = React.createRef()

        // this.setOpenTab = this.setOpenTab.bind(this)
        this.tickFormatter = this.tickFormatter.bind(this)
        this.getNettingValues = this.getNettingValues.bind(this)
        this.renderDataNetting = this.renderDataNetting.bind(this)
        this.onPeriodChange = this.onPeriodChange.bind(this)
        this.onGroupChange = this.onGroupChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.getAggregation = this.getAggregation.bind(this)
        this.getData = this.getData.bind(this)
        this.getQhData = this.getQhData.bind(this)
        this.renderForm = this.renderForm.bind(this)
        this.state = {
            period: undefined,
            from: null,
            to: null,
            tickFormatterAgg: undefined,
            id: undefined,
            agg: undefined,
            csvAgg: undefined,
            netting: undefined,
            groupList: [],
            csvData: [],
            loading: false,
            hasValues: true
        }

        this.strings = LocalizedStrings({
            en: {
                dtutc: "dtuct",
                dtlt: "dtlt",
                injectionVolume: "injectionVolume",
                offtakeVolume: "offtakeVolume",
                amount: "amount",
                nettingVolume: "nettingVolume",
                apply: "Apply",
                tomorrow: "Tomorrow",
                today: "Today",
                yesterday: "Yesterday",
                thisWeek: "This Week",
                previousWeek: "Previous Week",
                thisMonth: "This Month",
                previousMonth: "Previous Month",
                thisQuarter: "This Quarter",
                thisYear: "This Year",
                previousYear: "Previous Year",
                custom: "Custom",
                quatterHour: "Quarter Hour",
                nettingVolumeGraph: "Netting Volume",
                nettingFeeGraph: "Netting Fee",
                amountGraph: "Amount",
                offtakeInjection: "Offtake/Injection",
                loadingData: "Loading data.....",
                noDataAvailable: "No data available for selected period",
                hourly: "Hourly",
                aggregated: "Aggregated",
                notPartOfGroup: "Selected company is not a part of any netting group",
                total: "Total",
                export: "Export",
                csv: "CSV",
                download: "Download"
            },
            nl: {
                dtutc: "dtuct",
                dtlt: "dtlt",
                injectionVolume: "injectionVolume",
                offtakeVolume: "offtakeVolume",
                amount: "amount",
                nettingVolume: "nettingVolume",
                apply: "Apply",
                tomorrow: "Tomorrow",
                today: "Today",
                yesterday: "Yesterday",
                thisWeek: "This Week",
                previousWeek: "Previous Week",
                thisMonth: "This Month",
                previousMonth: "Previous Month",
                thisQuarter: "This Quarter",
                thisYear: "This Year",
                previousYear: "Previous Year",
                custom: "Custom",
                quatterHour: "Quarter Hour",
                nettingVolumeGraph: "Netting Volume",
                nettingFeeGraph: "Netting Fee",
                amountGraph: "Amount",
                offtakeInjection: "Offtake/Injection",
                loadingData: "Loading data.....",
                noDataAvailable: "No data available for selected period",
                hourly: "Hourly",
                aggregated: "Aggregated",
                notPartOfGroup: "Selected company is not a part of any netting group",
                total: "Total",
                export: "Export",
                csv: "CSV",
                download: "Download"
            }
        }, this.props.lang);

    }

    tickFormatter(value) {
        let format
        switch (this.state.tickFormatterAgg) {
            case 'h':
                format = 'YYYY-MM-DD HH:mm'
                break;

            case 'd':
                format = 'YYYY-MM-DD'
                break;

            case 'm':
                format = 'YYYY-MM'
                break;

            default:
                break;
        }

        return moment(value, 'YYYY-MM-DD HH:mm:ss').format(format)
    }

    componentDidMount() {
        // this.setState({ selectorSize: window.matchMedia('(max-width: 480px)').matches ? '211px' : window.matchMedia('(max-width: 650px)').matches ? '422px' : '' })
        // this.setOpenTab(window.location.hash.substring(1))
        this.renderDataNetting()
        this.setState({
            loading: true
        })
    }

    renderDataNetting() {
        groupList(null,
            (err, res) => {
                if (err) {
                    return window.reportError.send(err)
                } else {
                    this.setState({
                        groupList: res.groupList,
                        period: 'previousWeek',
                        from: moment().subtract(1, 'weeks').startOf('week').toDate(),
                        to: moment().subtract(1, 'weeks').endOf('week').toDate(),
                        agg: 'd',
                        tickFormatterAgg: 'd'
                    }, () => {
                        let id, beneficiaryCompanyId, companyId, checkBeneficiaryCompanyId

                        companyId = this.state.groupList.map(x => {
                            return {
                                id: x.id,
                                companies: x.companies !== null ? x.companies.split(',') : []
                            }
                        }).map(x => x.companies.includes(this.props.selectedCompany.companyNumber) ? x.id : null).find(x => x !== null)

                        checkBeneficiaryCompanyId = this.state.groupList.find(x => x.beneficiaryCompanyId === this.props.selectedCompany.companyNumber)

                        beneficiaryCompanyId = checkBeneficiaryCompanyId ? checkBeneficiaryCompanyId.id : undefined

                        if (beneficiaryCompanyId || companyId) {

                            id = companyId !== undefined ? companyId : beneficiaryCompanyId
                            this.getNettingValues(id)
                            this.setState({
                                id: id
                            })

                        } else {
                            this.setState({
                                loading: false,
                                hasValues: false
                            })
                        }
                    })
                }
            }
        )
    }

    getNettingValues(id) {

        getVolumes(id, this.state.from, this.state.to, this.state.agg,
            (err, res) => {
                if (err)
                    return window.reportError.send(err);
                if (res)
                    this.setState({
                        netting: res,
                        loading: false
                    });
            })
    }

    onGroupChange(event) {
        this.setState({
            groupId: event.target.value
        })
    }
    getAggregation() {
        switch (this.state.csvAgg) {
            case 'QH':
                return 'quarter-hour'

            case 'h':
                return 'hourly'

            case 'd':
                return 'daily-averages'

            case 'w':
                return 'weekly-averages'

            case 'm':
                return 'monthly-averages'

            default:
                break;
        }
    }
    getQhData() {

        this.setState({ loadingCsv: true })

        getVolumes(this.state.id, this.state.from, this.state.to, "QH",
            (err, res) => {
                if (err)
                    return window.reportError.send(err);

                this.setState({
                    csvData: res.values.map(x => {
                        return {
                            [this.strings.dtlt]: x.dtutc,
                            [this.strings.dtlt]: x.dtlt,
                            [this.strings.injectionVolume]: (x.injectionVolume * -1),
                            [this.strings.offtakeVolume]: x.offtakeVolume,
                            [this.strings.nettingVolume]: x.nettingVolume,
                            [this.strings.amount]: x.amount
                        }
                    }),
                    loadingCsv: false
                }, () => {
                    setTimeout(() => {
                        this.csvLink.current.link.click()
                    }, 0);
                });
            }
        );
    }
    getData() {

        this.setState({ loadingCsv: true })

        getVolumes(this.state.id, this.state.from, this.state.to, this.state.csvAgg,
            (err, res) => {
                if (err)
                    return window.reportError.send(err);

                this.setState({
                    csvData: res.values.map(x => {
                        return {
                            [this.strings.dtlt]: x.dtutc,
                            [this.strings.dtlt]: x.dtlt,
                            [this.strings.injectionVolume]: (x.injectionVolume * -1),
                            [this.strings.offtakeVolume]: x.offtakeVolume,
                            [this.strings.nettingVolume]: x.nettingVolume,
                            [this.strings.amount]: x.amount
                        }
                    }),
                    loadingCsv: false
                }, () => {
                    setTimeout(() => {
                        this.csvLink.current.link.click()
                    }, 0);
                });
            }
        );
    }
    onPeriodChange(event) {

        let agg


        if (event.target) {

            this.setState({
                period: event.target.value,
                from: periods[`${event.target.value}`].from,
                to: periods[`${event.target.value}`].to,
                agg: periods[`${event.target.value}`].agg,
                csvAgg: periods[`${event.target.value}`].agg
            })
        }

        if (event.id) {
            if (event.id === 'from') {
                const daysDiff = moment(this.state.to).diff(event.value, 'days')
                switch (true) {
                    case (daysDiff <= 7):

                        agg = 'h'

                        break;

                    case (daysDiff <= 100):

                        agg = 'd'

                        break;

                    case (daysDiff > 100):

                        agg = 'm'

                        break;

                    default:
                        break;
                }
                this.setState({
                    period: 'custom',
                    from: moment(event.value).endOf('day').toDate(),
                    agg: agg
                })
            } else {
                const daysDiff = moment(event.value).diff(this.state.from, 'days')
                switch (true) {
                    case (daysDiff <= 7):

                        agg = 'h'

                        break;

                    case (daysDiff <= 100):

                        agg = 'd'

                        break;

                    case (daysDiff > 100):

                        agg = 'm'

                        break;

                    default:
                        break;
                }
                this.setState({
                    period: 'custom',
                    to: moment(event.value).endOf('day').toDate(),
                    agg: agg,
                    csvAgg: agg
                })
            }
        }
    }
    onSubmit(e) {
        e.preventDefault()
        this.setState({
            netting: undefined,
            loading: true
        })
        getVolumes(this.state.id, this.state.from, this.state.to, this.state.agg,
            (err, res) => {
                if (err)
                    return window.reportError.send(err);
                if (res)
                    this.setState({
                        netting: res,
                        loading: false,
                        tickFormatterAgg: this.state.agg
                    });
            })
        return
    }

    renderForm() {
        const { from, to } = this.state
        const modifiers = { start: from, end: to }
        return (
            <form className="form-inline d-flex flex-column flex-md-row justify-content-center" onSubmit={this.onSubmit}>
                <select className="form-control mb-2 mr-sm-2" onChange={this.onPeriodChange} value={this.state.period} style={{ width: this.state.selectorSize }}>
                    {
                        Object.keys(periods).map(period => {
                            return <option key={period} value={period} onClick={this.onPeriodChange}>{this.strings[period]}</option>
                        })
                    }
                </select>
                <div className="date-picker to pickers">
                    <div className="DayPickerInput">
                        <DayPickerInput
                            value={from}
                            required
                            format="DD-MM-YYYY"
                            placeholder="FROM"
                            formatDate={(date, format) => { return moment(date).format(format) }}
                            inputProps={{ className: 'form-control mb-2 mr-sm-2', id: 'from', readOnly: 'readonly' }}
                            dayPickerProps={{
                                selectedDays: { from, to },
                                localeUtils: MomentLocaleUtils,
                                disabledDays: null,
                                modifiers,
                                firstDayOfWeek: 1,
                                numberOfMonths: 2,
                                locale: this.props.lang,
                            }}
                            onDayChange={date => this.onPeriodChange({ id: 'from', value: date })}
                        />
                    </div>
                </div>
                <div className="date-picker to pickers">
                    <div className="DayPickerInput">
                        <DayPickerInput
                            value={to}
                            required
                            format="DD-MM-YYYY"
                            placeholder="TO"
                            formatDate={(date, format) => { return moment(date).format(format) }}
                            inputProps={{ className: 'form-control mb-2 mr-sm-2', readOnly: 'readonly' }}
                            dayPickerProps={{
                                selectedDays: { from, to },
                                localeUtils: MomentLocaleUtils,
                                disabledDays: null,
                                modifiers,
                                firstDayOfWeek: 1,
                                numberOfMonths: 2,
                                locale: this.props.lang,
                            }}
                            onDayChange={date => this.onPeriodChange({ id: 'to', value: date })}
                        />
                    </div>
                </div>
                <button className="btn btn-primary btn-picker mb-2" type='submit'>{this.strings.apply}
                    <i className="fas fa-chevron-right ml-1"></i>
                </button>
            </form>
        )
    }


    render() {

        return (
            this.state.loading === true && !this.state.netting ? <Loader>{this.strings.loadingData}</Loader> : this.state.hasValues === false ?
                <h5 className="text-center text-info mb-3">
                    {this.strings.notPartOfGroup}
                </h5>
                : this.state.netting && !this.state.netting.values.length ? <div className="col-sm py-2 mb-4">
                    {this.renderForm()}
                    <h5 className="text-center text-info mb-3">
                        {this.strings.noDataAvailable}
                    </h5>
                </div> :
                    this.state.netting ?
                        <div className="tab-pane active d-block">
                            <div className="row">
                                <div className="col-sm py-2 mb-4">
                                    {this.renderForm()}
                                </div>
                            </div>
                            <div className="row mt-3 mb-5">
                                <div className="col-sm d-flex justify-content-around flex-column flex-md-row">
                                    <div className="mb-2 text-center">
                                        <h5 className="text-primary font-weight-light mb-2">
                                            <span className="font-weight-bold">{this.strings.nettingVolumeGraph}</span>
                                        </h5>
                                        <h4 className="text-primary font-weight-light">
                                            {this.state.netting.totalVolume} MWh
                                        </h4>
                                    </div>
                                    <div className="border-right border-warning w-0 mb-2"></div>
                                    <div className="mb-2 text-center">
                                        <h5 className="text-primary font-weight-light mb-2">
                                            Total <span className="font-weight-bold">{this.strings.amountGraph}</span>
                                        </h5>
                                        <h4 className="text-primary font-weight-light">
                                            {this.state.netting.totalAmount} €
                                        </h4>
                                    </div>
                                    <div className="border-right border-warning w-0 mb-2"></div>
                                    <div className="mb-2 text-center">
                                        <h5 className="text-primary font-weight-light mb-2">
                                            <span className="font-weight-bold">Injection Volume</span>
                                        </h5>
                                        <h4 className="text-primary font-weight-light">
                                            {this.state.netting.totalInjection} MWh
                                        </h4>
                                    </div>
                                    <div className="border-right border-warning w-0 mb-2"></div>
                                    <div className="mb-2 text-center">
                                        <h5 className="text-primary font-weight-light mb-2">
                                            <span className="font-weight-bold">Offtake Volume</span>
                                        </h5>
                                        <h4 className="text-primary font-weight-light">
                                            {this.state.netting.totalOfftake} MWh
                                        </h4>
                                    </div>
                                    <div className="border-right border-warning w-0 mb-2"></div>
                                    <div className="mb-2 text-center">
                                        <h5 className="text-primary font-weight-light mb-2">
                                            <span className="font-weight-bold">Netting Percentage</span>
                                        </h5>
                                        <h4 className="text-primary font-weight-light">
                                            {this.state.netting.totalPercentage} %
                                        </h4>
                                    </div>
                                    <div className="border-right border-warning w-0 mb-2"></div>
                                    <div className="mb-2 text-center">
                                        <h5 className="text-primary font-weight-light mb-2">
                                            <span className="font-weight-bold">{this.strings.nettingFeeGraph}</span>
                                        </h5>
                                        <h4 className="text-primary font-weight-light">
                                            {this.state.netting.values[0].nettingFee} € / MWh
                                        </h4>
                                    </div>
                                    <div className="border-right border-warning w-0 mb-2"></div>
                                    <div className="mb-2 text-center">
                                        <h5 className="text-primary font-weight-light mb-2">
                                            <span className="font-weight-bold">{this.strings.export}</span> {this.strings.csv}
                                        </h5>
                                        {this.state.loadingCsv === true ?
                                            <i className="fas fa-spinner fa-spin" /> : <h4 className="text-primary font-weight-light" style={{ width: '130px', height: '38px' }}>
                                                <div className="mb-2 text-center">
                                                    <div className="notifications-dropdown">
                                                        <div className="btn bg-white text-left text-primary font-weight-light cursor-default" style={{ fontSize: '1rem' }}>
                                                            <i className="fas fa-download mr-2"></i> {this.strings.download}
                                                        </div>

                                                        <div className="notifications-menu download-menu">
                                                            <button type="button" name='Quatter Hour' className="btn text-left w-100 pl-4 py-3" value="0" onClick={() => this.getQhData()}>
                                                                {this.strings.quatterHour}
                                                            </button>
                                                            <button type="button" name='Hourly' className="btn text-left w-100 px-4 py-3" value="1" onClick={() => this.getData()}>
                                                                {this.strings.hourly}
                                                            </button>
                                                            <button type="button" name='Aggregated' className="btn text-left w-100 px-4 py-3" value="2" onClick={() => this.getData()}>
                                                                {this.strings.aggregated}
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <CSVLink
                                                        className="d-none"
                                                        data={this.state.csvData}
                                                        separator=";"
                                                        filename={`Yuso-${this.state.groupList.find(x => x.id === this.state.id).name}-netting group-${this.getAggregation()}-values`}
                                                        header={[this.strings.dtutc, this.strings.dtlt, this.strings.injectionVolume, this.strings.offtakeVolume, this.strings.nettingVolume, this.strings.amount]}
                                                        ref={this.csvLink}
                                                    />
                                                </div>
                                            </h4>}

                                    </div>
                                </div>
                            </div>
                            <h5 className="text-center text-info mb-3">
                                {this.strings.nettingFeeGraph} (EUR/MWh)
                            </h5>
                            <div className="col-sm" style={{ height: '300px' }}>
                                <ResponsiveContainer>
                                    <LineChart data={this.state.netting.values}>
                                        <XAxis dataKey="dtlt" type="category" tickFormatter={this.tickFormatter} minTickGap={50} />
                                        <YAxis domain={[0, this.state.netting.values[0].nettingFee + 5]} />
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <Tooltip labelFormatter={this.tickFormatter} />
                                        <Line
                                            type="stepAfter"
                                            dot={false}
                                            name="Netting Fee"
                                            dataKey="nettingFee"
                                            unit=" € / MWh"
                                        />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                            <h5 className="text-center text-info mb-3">
                                {this.strings.nettingVolumeGraph} (MWh)
                            </h5>
                            <div className="col-sm" style={{ height: '300px' }}>
                                <ResponsiveContainer>
                                    <AreaChart data={this.state.netting.values} className="pl-chart">
                                        <XAxis dataKey="dtlt" type="category" tickFormatter={this.tickFormatter} minTickGap={50} />
                                        <YAxis />
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <Tooltip labelFormatter={this.tickFormatter} formatter={values => parseFloat(values)} />
                                        <Area
                                            type="stepAfter"
                                            name='Netting Volume'
                                            dataKey="nettingVolume"
                                            unit=" MWh"
                                            stroke='dddc01'
                                            fill="#dddc01"
                                        />
                                    </AreaChart>
                                </ResponsiveContainer>
                            </div>
                            <h5 className="text-center text-info mb-3">
                                Netting Amount (EUR)
                            </h5>
                            <div className="col-sm" style={{ height: '300px' }}>
                                <ResponsiveContainer>
                                    <BarChart data={this.state.netting.values} className="pl-chart">
                                        <XAxis dataKey="dtlt" type="category" tickFormatter={this.tickFormatter} minTickGap={50} />
                                        <YAxis />
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <Tooltip labelFormatter={this.tickFormatter} formatter={values => parseFloat(values)} />
                                        <Bar
                                            name='Amount'
                                            dataKey="amount"
                                            unit=" €"
                                            fill="#dddc01"
                                        />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                            <h5 className="text-center text-info mb-3">
                                {this.strings.offtakeInjection}/Netting (MWh)
                            </h5>
                            <div className="col-sm" style={{ height: '300px' }}>
                                <ResponsiveContainer width="100%" height="100%">
                                    <BarChart
                                        data={this.state.netting.values}
                                        className="pl-chart"
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="dtlt" tickFormatter={this.tickFormatter} />
                                        <XAxis
                                            dataKey="dtlt"
                                            axisLine={false}
                                            tickLine={false}
                                            interval={0}
                                            scale="band"
                                        />
                                        <YAxis />
                                        <Tooltip labelFormatter={this.tickFormatter} formatter={values => parseFloat(values)} />
                                        <Legend />
                                        <ReferenceLine stroke="#000" />
                                        <Bar dataKey="offtakeVolume" fill="#006087" unit=" MWh" name='Offtake' />
                                        <Bar dataKey="injectionVolume" fill="#93b0c5" unit=" MWh" name='Injection' />
                                        <Bar dataKey="nettingVolume" fill="#dddc01" unit=" MWh" name='Netting Volume' />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </div> : null
            //#93b0c5
            //#dddc01
        )
    }

}

export default NettingChart;